.progress-bar {
    position: relative;
    transition: opacity ease .5s;
}

.progress-bar .progress-bar__bar {
    height: 25px;
}

.progress-bar .progress-bar__bar.progress-bar__bar--target {
    background-color: #ccc;
    border-radius: 5px;
    width: 100%
}

.progress-bar .progress-bar__bar.progress-bar__bar--current {
    background-color: #2f3238;
    border-radius: 5px 0 0 5px;
    position: absolute;
    top: 0;
    transition: width ease .5s;
    width: 0;
}

.progress-bar .progress-bar__text {
    margin-top: 5px;
}