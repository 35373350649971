@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src:
    url('../../assets/fonts/lato-v16-latin_latin-ext-regular.woff2') format('woff2'),
    url('../../assets/fonts/lato-v16-latin_latin-ext-regular.woff') format('woff'),
    url('../../assets/fonts/lato-v16-latin_latin-ext-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src:
    url('../../assets/fonts/lato-v16-latin_latin-ext-700italic.woff2') format('woff2'),
    url('../../assets/fonts/lato-v16-latin_latin-ext-700italic.woff') format('woff'),
    url('../../assets/fonts/lato-v16-latin_latin-ext-700italic.ttf') format('truetype');
}

@font-face {
  font-family: 'DINCond-Bold';
  font-style: normal;
  font-weight: 700;
  src:
    url('../../assets/fonts/DINCond-Bold.woff2') format('woff2'),
    url('../../assets/fonts/DINCond-Bold.woff') format('woff'),
    url('../../assets/fonts/DINCond-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'DINCond';
  font-style: normal;
  font-weight: 500;
  src:
    url('../../assets/fonts/DINCond-Medium.woff2') format('woff2'),
    url('../../assets/fonts/DINCond-Medium.woff') format('woff'),
    url('../../assets/fonts/DINCond-Medium.ttf') format('truetype');
}
