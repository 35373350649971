.form {
  &-line {
    border-bottom: 5px solid $btn-color;
    width: 40px;
  }
}

.form-group {
  color: $txt-color;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.62px;

  label {
    margin-bottom: 0.3rem;
  }

  .form-control {
    border-radius: 0.4rem;
    border: 2px solid #cccccc;
    color: $txt-color;
    &:focus{
      box-shadow: none;
    }
  }
}

.form-check {
  padding: 0;
}

form .form-check > .box {
  background-color: #f9f9f9;
  border: 0.2rem solid #ccc;
  border-radius: 0.35rem;
  display: inline-block;
  flex: 0 0 auto;
  height: 2rem;
  margin-right: 0.5rem;
  margin-top: 0.2rem;
  position: relative;
  vertical-align: middle;
  width: 2rem;
}

form .form-check input {
  cursor: pointer;
  left: 0;
  position: absolute;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  opacity: 0.01;
  white-space: nowrap;
  border: 0;
  height: 2rem;
  top: 0;
  width: 2rem;
}

form .form-check > .box::before {
  border-radius: 0.175rem;
  content: "";
  height: 1.2rem;
  left: 0.2rem;
  position: absolute;
  top: 0.2rem;
  transition: background-color 0.2s;
  width: 1.2rem;
}

form .form-check input[type="checkbox"]:checked + .box::before {
  background-image: url(../../assets/svg/check.679163f9.svg);
  background-position: 50%;
  background-size: contain;
}

.form-check-label {
  cursor: pointer;
  color: $txt-color;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 0.8rem;
  letter-spacing: 0.62px;
}

.form-check-input {
  margin: 0;
  left: 12px;
  z-index: 100;
}
/* FORM: Styling checkbox  END */

.btn-primary {
  background-color: $btn-color;
  border-radius: 0.4rem;
  border: 2px solid $btn-color;
  padding: 25px 55px;
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 0.9rem;
  letter-spacing: 2.6px;
  text-transform: uppercase;

  &:hover {
    background-color: #fff;
    color: $btn-color;
    border-color: $btn-color;
  }

  &:disabled {
    background-color: #cccccc;
    border-color: #cccccc;
  }
}

.form-error {
  font-family: 'Lato', sans-serif;
  font-size: 1.2rem;
  color: $btn-color;
}