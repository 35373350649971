$bg-color: #eceff1;
$btn-color: #fa025f;
$txt-color: #666;

body {
  background-color: $bg-color;
}

img {
  max-width: 100%;
}

p {
  font-family: 'Lato', sans-serif;
}

a {
  color: $btn-color;

  &:hover {
    color: $btn-color;
  }
}

.headline {
  text-transform: uppercase;
  font-family: 'DINCond-Bold', sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 2rem;
  letter-spacing: -1px;

  @include media-breakpoint-up(sm) {
    font-size: 3.5rem;
    line-height: 3.125rem;
  }
}

.headline-logo h1 {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  display: inline-block;
}

.teaser {
  position: relative;

  &-rec-grey {
    position: absolute;
    width: 535px;
    max-width: 100%;
    height: 526px;
    background-color: #afaea9;
    top: 0;
    opacity: 0;
    animation: recAnimation 1s 0.5s forwards;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &-rec-blue {
    position: relative;
    width: 341px;
    height: 419px;
    max-width: 100%;
    background-color: #81b7f9;
    margin-top: 200px;
    margin-left: -40px;
    opacity: 0;
    animation: recAnimation 1s 1s forwards;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &-image {
    position: absolute;
    top: -100px;

    @include media-breakpoint-down(md) {
      top: -120px;
      transform: scale(0.8);
    }

    @include media-breakpoint-down(sm) {
      top: 0;
      position: relative;
      transform: scale(1);
    }
  }
}

.footer {
  &-logos {
    justify-content: center;
    align-items: center;
  }

  &-line {
    border-left: 1px solid black;
    height: 52px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &-apostrophe {
    display: inline-block;

    @include media-breakpoint-down(sm) {
      font-size: 0.7rem;
    }
  }

  &-logo-verfund {
    display: inline-block;
    padding-top: 12px;
  }
}

.thx {
  &-container {
    display: none;
    max-width: 698px;
    width: 100%;
    background-color: $bg-color;
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
  }

  &-image {
    padding: 10px;
  }

  &-line {
    border-bottom: 5px solid $btn-color;
    width: 40px;
    display: inline-block;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }

  &-headline {
    text-align: center;
  }

  &-txt {
    text-align: center;
    color: $txt-color;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-style: italic;
  }

  &-overlay {
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
  }
}

@keyframes recAnimation {
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}
